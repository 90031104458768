import React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { H1 } from "../components/commons"
import Looks from "../components/looks"

const Section = styled.section`
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 1rem;
`

const P = styled.p`
  text-align: center;
  font-family: franklin-gothic-urw, sans-serif;
  font-weight: 400;
  font-size: 1.2rem;
  color: #615c53;
`

export default function NotFoundPage({ data }) {
  const looks = data.prismic.latestLooks.edges
  return (
    <Layout>
      <SEO title="404: Not found" />
      <Section>
        <H1>whoops! page not found</H1>
        <P>
          You just hit a page that doesn&#39;t exist... take a look at our
          latest publications.
        </P>
        <Looks looks={looks} />
      </Section>
    </Layout>
  )
}

export const NotFoundQuery = graphql`
  query LatestLooks {
    prismic {
      latestLooks: allLooks(sortBy: meta_firstPublicationDate_DESC, last: 3) {
        edges {
          node {
            _meta {
              uid
              firstPublicationDate
            }
            clothing {
              brand {
                ... on PRISMIC_Brand {
                  name
                  _linkType
                }
              }
              category {
                ... on PRISMIC_Category {
                  name
                  _linkType
                }
              }
            }
            author {
              ... on PRISMIC_Author {
                username
                website {
                  ... on PRISMIC__ExternalLink {
                    url
                  }
                }
              }
            }
            title
            image
            color
          }
        }
      }
    }
  }
`
