import React from "react"
import { Link } from "gatsby"
import styled, { keyframes } from "styled-components"
import hexToRgba from "hex-to-rgba"
import tinytime from "tinytime"

import { linkResolver } from "../utils/linkResolver"
import { UnstyledList } from "../components/commons"

const slideOutRight = keyframes`
  0% {
    left: 5px;
    opacity: 0
  }
  20% {
    opacity: 1;
  }

  80% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    left: 15px;
  }
`

const StyledLooks = styled(UnstyledList)`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  gap: 1.3rem;

  @media (min-width: 640px) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 1.1rem 1.1rem;
  }
`

const StyledItem = styled.li`
  position: relative;
  overflow: hidden;

  img {
    margin-bottom: 0;
    display: block;
    transition: transform 0.2s linear;
  }

  @media (min-width: 640px) {
    &:hover section {
      opacity: 1;
    }

    &:hover h2 {
      opacity: 1;
    }

    &:hover time {
      opacity: 1;
    }

    &:hover section > * {
      opacity: 1;
    }

    &:hover img {
      @media (min-width: 640px) {
        transform: scale(1.5);
      }
    }

    &:hover footer div {
      opacity: 1;
    }
  }
`

const HoverSection = styled.section`
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  @media (min-width: 640px) {
    background-color: ${({ backgroundColor }) => backgroundColor};
    position: absolute;
    display: flex;
    flex-direction: column;
    opacity: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: opacity 0.2s linear;
    z-index: 2;
  }
`

const Header = styled.header`
  margin-top: 3.9rem;
  margin-bottom: 1.95rem;
  text-align: center;
`

const H2 = styled.h2`
  text-align: center;
  font-family: franklin-gothic-urw, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 2.375rem;
  margin-bottom: 0.65rem;
  line-height: 2.6rem;
  color: #fff;
  opacity: 1;
  position: absolute;
  top: 2rem;
  right: 2rem;

  @media (min-width: 640px) {
    position: relative;
    opacity: 0;
    top: 0;
    right: 0;
  }
`

const Time = styled.time`
  color: #fff;
  font-weight: 300;
  font-size: 0.875rem;
  font-style: italic;
  opacity: 0;
`

const Brands = styled(UnstyledList)`
  flex: 1 0 auto;
  color: #fff;
  text-align: center;
  font-family: franklin-gothic-urw, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.125rem;
  opacity: 0;

  li {
    line-height: 1.9rem;
  }
`

const Footer = styled.footer`
  font-family: franklin-gothic-urw, serif;
  font-weight: 300;
  font-style: normal;
  font-size: 4rem;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  position: absolute;
  bottom: 2rem;
  right: 2rem;

  @media (min-width: 640px) {
    position: relative;
    opacity: 0;
    bottom: 0;
    right: 0;
    margin-bottom: 3.9rem;
    line-height: 2.6rem;
  }

  div {
    color: #fff;
    transform: rotate(-4deg);
    position: relative;
    animation: ${slideOutRight} 1s ease-out infinite;
  }
`

function Look(item, index) {
  const { node } = item
  const title = node.title
  const color = node.color
  const img = node.image
  const brands = [
    ...new Set(node.clothing && node.clothing.map(({ brand }) => brand.name)),
  ]
  const uid = node._meta.uid

  const firstPublicationDate = node._meta.firstPublicationDate
  const dateTemplate = tinytime("{MMMM} {DD}")
  const date = dateTemplate.render(new Date(firstPublicationDate))

  return (
    <StyledItem key={index} style={{ backgroundColor: color }}>
      <Link to={linkResolver({ type: "look", uid })}>
        <article>
          <img src={img.url} alt={img.alt} />

          <HoverSection backgroundColor={hexToRgba(color, "0.99")}>
            <Header>
              <H2>{title}</H2>
              <Time dateTime={firstPublicationDate}>{`— ${date} —`}</Time>
            </Header>
            <Brands>
              {brands.map(brand => (
                <li key={brand}>{brand}</li>
              ))}
            </Brands>
            <Footer>
              <div>⇝</div>
            </Footer>
          </HoverSection>
        </article>
      </Link>
    </StyledItem>
  )
}

export default function Looks({ looks = [] }) {
  return <StyledLooks>{looks.map(Look)}</StyledLooks>
}
